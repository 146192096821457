import { isDevelopment } from '~/config'
import { Utils } from '~/tenset-web3'

const { Network } = Utils

export const BSC_NETWORK = isDevelopment
  ? Network.ANVIL_BSC // ANVIL_BSC | PHALCON_BSC
  : Network.SMART_CHAIN

export const MAINNET_NETWORK = isDevelopment
  ? Network.ANVIL_ETH // ANVIL_ETH | PHALCON_ETH
  : Network.ETHEREUM
