const agreementConfig = {
  id: 1,
}

const googleAnalyticsConfig = {
  id: 'G-5RQNR0PNFR',
}

const hotjarConfig = {
  id: 3_169_407,
  version: 6,
}

export { agreementConfig, googleAnalyticsConfig, hotjarConfig }
